"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _Svg = _interopRequireDefault(require("./Svg"));

var __jsx = _react["default"].createElement;

var WarningSvg = function WarningSvg(props) {
  return __jsx("svg", props, __jsx("g", {
    fill: "none",
    fillRule: "evenodd"
  }, __jsx("path", {
    fill: "#000",
    d: "M15 6l10 18H5z",
    opacity: ".4"
  }), __jsx("path", {
    fill: "#FFF",
    d: "M14 13h2v5h-2v-5zm0 6h2v2h-2v-2z"
  })));
};

WarningSvg.defaultProps = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "30",
  height: "30",
  viewBox: "0 0 30 30"
};

var WarningGrayBlock = function WarningGrayBlock(_ref) {
  var message = _ref.message;
  return __jsx("div", {
    className: "warning-gray-block"
  }, __jsx("div", {
    className: "warning-gray-block__icon"
  }, __jsx(_Svg["default"], {
    src: WarningSvg
  })), __jsx("div", {
    className: "warning-gray-block__text",
    dangerouslySetInnerHTML: {
      __html: message
    }
  }));
};

var _default = WarningGrayBlock;
exports["default"] = _default;