"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isXRP = isXRP;
exports.isXLM = isXLM;
exports.isETH = isETH;
exports.parseXRPLink = parseXRPLink;
exports.parseXLMLink = parseXLMLink;

var _toLower2 = _interopRequireDefault(require("lodash/toLower"));

var dtRegex = /.*\?dt=(\d+)/;
var memoidRegex = /.*\?memoid=(\d+)/i;

function isXRP(currency) {
  return (0, _toLower2["default"])(currency) === 'xrp';
}

function isXLM(currency) {
  return (0, _toLower2["default"])(currency) === 'xlm';
}

function isETH(currency) {
  return (0, _toLower2["default"])(currency) === 'eth';
}

function parseXRPLink(link) {
  var lowered = (0, _toLower2["default"])(link);
  var result = dtRegex.exec(lowered);

  if (result === null) {
    return null;
  }

  var address = link.substr(0, link.indexOf('?'));
  return {
    address: address,
    dt: result[1]
  };
}

function parseXLMLink(link) {
  var lowered = (0, _toLower2["default"])(link);
  var result = memoidRegex.exec(lowered);

  if (result === null) {
    return null;
  }

  var address = link.substr(0, link.indexOf('?'));
  return {
    address: address,
    memoid: result[1]
  };
}