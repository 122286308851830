import React, { Component } from 'react';
import PropTypes from 'prop-types';

import BmcForm from 'bmc-account-next/lib/js/components/bmc/BmcForm';
import BmcRate, { prepareWarningMessage } from 'bmc-account-next/lib/js/components/bmc/BmcRate';
import DepositCredentials from 'bmc-account-next/lib/js/components/deposit/DepositCredentials';

class FormComponent extends Component {
  static propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
  }

  state = {
    depositData: null,
    rateData: null,
  }

  handleFormSuccess = (depositData) => {
    this.setState({
      depositData,
    });
  }

  handleChangeRateData = (rateData) => {
    this.setState({
      rateData,
    });
  }

  render() {
    const { depositData, rateData } = this.state;

    const warningMessage = prepareWarningMessage(rateData);

    return (
      <div className="sto-form" id="bmc_converter">
        <div className="apply-mission-form">
          <h3 className="title">BMC Converter</h3>
          <BmcRate
            onChangeRateData={ this.handleChangeRateData }
          />
          {
            depositData
              ?
              <div className="bmc-form">
                <div className="bmc-modal-content__title"></div>
                <DepositCredentials
                  address={ depositData.address }
                  qr={ depositData.qrLink }
                  currency={ "bmc" }
                  availableUntil={ depositData.availableUntil }
                  isReady={ depositData.isReady }
                  minAmount={  depositData.minAmount }
                />
              </div>

              : <BmcForm
                isLoggedIn={ this.props.isLoggedIn }
                onSuccess={ this.handleFormSuccess }
                preventFocusOnMount
                userEmail={ this.props.userEmail }
                warningMessage={ warningMessage }
              />
          }
        </div>
      </div>
    );
  }
}

export default FormComponent;
