"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formattedPercent = formattedPercent;
exports.preparePercent = preparePercent;

function formattedPercent(percent) {
  return Math.floor(percent) - percent === 0 ? percent.toFixed(1) : percent.toFixed(2);
}

function preparePercent(percent) {
  var formatted = formattedPercent(percent);
  return "".concat(percent > 0 ? '+' + formatted : formatted, "%");
}