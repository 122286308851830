import React, { Component } from 'react'
import PropTypes from 'prop-types';

import Button from 'bmc-account-next/lib/js/components/Button';
import loginService from 'bmc-account-next/lib/js/services/Login';
import mixpanel from 'bmc-account-next/lib/js/services/mixpanel';
import { isLoggedIn } from 'bmc-account-next/lib/js/server/helpers/user';
import { Link } from 'react-scroll';

function TradeSvg() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
        <path fill="#fff" fillRule="nonzero" d="M20.586 11H7V9h13.586l-1.5-1.5L20.5 6.086 24.414 10 20.5 13.914 19.086 12.5l1.5-1.5zM9.414 19H23v2H9.414l1.5 1.5L9.5 23.914 5.586 20 9.5 16.086l1.414 1.414-1.5 1.5z"/>
    </svg>

  )
}

function ExportSvg() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
        <path fill="#fff" fillRule="nonzero" d="M16 10.828V17h-2v-6.172l-2.293 2.293-1.414-1.414L15 7l4.707 4.707-1.414 1.414L16 10.828zM8 21h14v2H8v-2z"/>
    </svg>
  )
}

function BmcLogoSvg() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="320" height="100" viewBox="0 0 160 50">
      <path fill="#fff" fill-rule="evenodd" d="M73.549 28.23H77.5v2.127h-6.072V19.644h2.12v8.585zm11.63-8.587l5.179 10.715h-2.354l-3.182-6.43-3.152 6.43h-2.384l5.179-10.715h.714zm13.65 2.492c-2.123-1.385-5.322-.095-5.322 2.864 0 3.102 3.386 4.346 5.446 2.772l1.405 1.62a5.657 5.657 0 0 1-3.621 1.323c-3.12 0-5.665-2.565-5.665-5.715 0-3.147 2.544-5.714 5.665-5.714 1.31 0 2.529.457 3.496 1.213l-1.405 1.637zm6.266 3.324l-.808.842v4.057h-2.144V19.643h2.144v3.46l3.105-3.46h2.875l-3.727 4.193 3.817 6.522h-2.54l-2.722-4.899zm15.62 4.899V23.8l-3.214 2.797-3.214-2.797v6.558h-2.144V19.643h.756l4.602 4.066 4.587-4.066h.77v10.715h-2.143zM136.073 25a5.725 5.725 0 0 1-5.715 5.714A5.724 5.724 0 0 1 124.644 25a5.724 5.724 0 0 1 5.714-5.714A5.724 5.724 0 0 1 136.073 25zm12.856 0a5.724 5.724 0 0 1-5.714 5.714A5.725 5.725 0 0 1 137.5 25a5.724 5.724 0 0 1 5.715-5.714A5.724 5.724 0 0 1 148.93 25zm-22.143 0a3.57 3.57 0 0 0 3.572 3.571 3.57 3.57 0 1 0 0-7.142 3.57 3.57 0 0 0-3.572 3.57zm12.858 0a3.57 3.57 0 0 0 3.57 3.571 3.57 3.57 0 1 0 0-7.142 3.57 3.57 0 0 0-3.57 3.57zm19.755 5.358l-6.54-6.251v6.25h-2.144V19.644h.715l6.429 5.992v-5.992H160v10.715h-.602zM65.78 19.644a3.143 3.143 0 0 1 3.139 3.138 3.03 3.03 0 0 1-.55 1.758c.78.6 1.27 1.531 1.27 2.573a3.237 3.237 0 0 1-3.245 3.245h-3.536V19.643h2.922zm.583 8.893c.72 0 1.315-.567 1.315-1.286 0-.718-.595-1.3-1.315-1.3h-1.72v2.586h1.72zm-.674-4.654c.597 0 1.24-.488 1.24-1.238 0-.752-.643-1.21-1.24-1.21h-1.045v2.448h1.045zM25 0c13.805 0 25 11.193 25 25.001C50 38.807 38.805 50 25 50 11.19 50 0 38.807 0 25.001 0 11.193 11.191 0 25 0zM23.57 45c11.047 0 20-8.954 20-19.999 0-11.046-8.953-20.001-20-20.001-11.046 0-20 8.956-20 20.001 0 11.045 8.955 20 20 20z"/>
    </svg>
  )
}

class IndexHeader extends Component {
  static propTypes = {
    userData: PropTypes.object,
  }

  handleLoginClick(showModal, e) {
    e.preventDefault();

    switch (showModal) {
      case 'register':
        mixpanel.get().track('Register start', { "Register button": 'GetStartedMain' });
        break;
      case 'login':
        mixpanel.get().track('Login start');
        break;
    }

    if (isLoggedIn(this.props.userData)) {
      window.location = '/dashboard';
      return;
    }

    loginService.doLogin(
      function () {
        window.location = '/dashboard';
      },
      showModal
    );
  }

  toDashboard() {
    window.location = '/dashboard';
  }

  render() {
    return (
      <header className="new-header main__header">
        <div className="index__container">
          <div className="new-header_main__wrapper">
            <div className="new-header__warning">
              < BmcLogoSvg />
            </div>
            {/*<div className="new-header__withdraw-buttons">*/}
            {/*  /!*<Button className="new-header__main-button button--new-blue" onClick={ this.handleLoginClick.bind(this, 'login') }><ExportSvg /> Withdraw funds</Button>*!/*/}
            {/*  /!*{*!/*/}
            {/*  /!*  !isLoggedIn(this.props.userData)*!/*/}
            {/*  /!*  ? <div className="new-header__mobile-auth-buttons">*!/*/}
            {/*  /!*      <Button className="new-header__main-auth-fill-button button--new-blue" onClick={ this.handleLoginClick.bind(this, 'login') }><ExportSvg /> Withdraw funds</Button>*!/*/}
            {/*  /!*    </div>*!/*/}
            {/*  /!*  : <div className="new-header__mobile-auth-buttons">*!/*/}
            {/*  /!*    <Button className="new-header__main-auth-fill-button button--new-blue" onClick={ this.toDashboard }><ExportSvg /> Withdraw funds</Button>*!/*/}
            {/*  /!*  </div>*!/*/}
            {/*  /!*}*!/*/}
            {/*  <Link to="bmc_converter" className="button bmc-converter__button" href="#bmc_converter" smooth={ true } duration={ 300 } offset={ -80 }>*/}
            {/*    <TradeSvg /> BMC Converter*/}
            {/*  </Link>*/}
            {/*</div>*/}
          </div>
        </div>
      </header>
    );
  }
}

export default IndexHeader;
