import React from 'react'
import Layout from '../components/layout';
import Nav from '../components/nav';
import NewIndexHeader from '../components/newIndexHeader';
import BmcFormBlock from '../components/bmcFormBlock';
// import Fest from '../components/fest';
import UserDataProvider from '../components/userdata-provider/userDataProvider';
import { UserDataContext } from '../components/userdata-provider/userdata-context';
import { isLoggedIn } from 'bmc-account-next/lib/js/server/helpers/user';

const WhitelistedPage = ({ location, data }) => {
  {/* <Fest /> */}
  return (
    <Layout
    title="Blackmoon"
    location={ location }
    metaDescription="Blackmoon"
    metaKeywords="Blackmoon"
    disableFooter="true"
    >
      <UserDataProvider>
        <UserDataContext.Consumer>
          { userData => (
            <React.Fragment>
              {/*<Nav location={ location }*/}
              {/*  isLoggedIn={ isLoggedIn(userData) }*/}
              {/*  userData={ userData }*/}
              {/*/>*/}
              <NewIndexHeader userData={ userData } />
            </React.Fragment>
          )}
        </UserDataContext.Consumer>
      </UserDataProvider>
      {/*<UserDataProvider>*/}
      {/*  <UserDataContext.Consumer>*/}
      {/*    { userData => (*/}
      {/*      <React.Fragment>*/}
      {/*        <BmcFormBlock*/}
      {/*          isLoggedIn={ isLoggedIn(userData) }*/}
      {/*          userEmail={ userData && userData.email }*/}
      {/*        />*/}
      {/*      </React.Fragment>*/}
      {/*    )}*/}
      {/*  </UserDataContext.Consumer>*/}
      {/*</UserDataProvider>*/}
    </Layout>
  )
}

export default WhitelistedPage
