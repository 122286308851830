"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.prepareMoney = prepareMoney;
exports.formatCurrency = formatCurrency;
exports.prepareCurrencyTitle = prepareCurrencyTitle;
exports.delimiterPositionFormatter = exports.moneyFormat = void 0;

var _toUpper2 = _interopRequireDefault(require("lodash/toUpper"));

var formatMoney = function formatMoney(n, c, d, t) {
  var c = isNaN(c = Math.abs(c)) ? 2 : c,
      d = d == undefined ? "." : d,
      t = t == undefined ? "," : t,
      s = n < 0 ? "-" : "",
      i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
      j = (j = i.length) > 3 ? j % 3 : 0;
  return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
};

var moneyFormat = function moneyFormat(value, c, d, t) {
  return formatMoney(value, c, d, ' ');
};

exports.moneyFormat = moneyFormat;

var delimiterPositionFormatter = function delimiterPositionFormatter(value) {
  var minIntDig = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  var minFractDig = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
  var maxFractDig = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 3;
  var maxSignDig = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 1;
  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumIntegerDigits: minIntDig,
    minimumFractionDigits: minFractDig,
    maximumFractionDigits: maxFractDig,
    maximumSignificantDigits: maxSignDig
  }).format(value);
};

exports.delimiterPositionFormatter = delimiterPositionFormatter;

function prepareIntegerDelimeter(stringValue) {
  var delimeter = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ',';

  if (stringValue.length <= 3) {
    return stringValue;
  }

  var notDelimetedCount = stringValue.length % 3;
  var resultValue = '';

  for (var i = 0; i < stringValue.length; i += 1) {
    if ((i - notDelimetedCount) % 3 === 0 && i !== 0) {
      resultValue += delimeter;
    }

    resultValue += stringValue[i];
  }

  return resultValue;
}

function prepareZeros(count) {
  var result = '';

  while (count-- > 0) {
    result += '0';
  }

  return result;
}

function prepareMoney(value, digits, fillZeros) {
  var stringValue = value.toString(10);
  var parts = stringValue.split('.');

  if (parts.length === 1) {
    return prepareIntegerDelimeter(stringValue) + (fillZeros ? '.' + prepareZeros(digits) : '');
  }

  var fractPart = parts[1].substr(0, digits);
  return "".concat(prepareIntegerDelimeter(parts[0]), ".").concat(fractPart + (fillZeros ? prepareZeros(digits - fractPart.length) : ''));
}

function formatCurrency(value, currency, fillZeros, round) {
  var fractDigits = 0;

  switch (currency) {
    case 'eth':
    case 'btc':
    case 'ltc':
    case 'ETH':
    case 'BTC':
    case 'LTC':
      fractDigits = 6;
      break;

    case 'usdc':
    case 'USDC':
      fractDigits = 10;
      break;

    case 'usd':
    case 'tusd':
    case 'eur':
    case 'bmc':
    case 'USD':
    case 'TUSD':
    case 'USDT':
    case 'EUR':
    case 'BMC':
      fractDigits = 2;
      break;

    case 'token':
      fractDigits = 10;
      break;

    default:
      fractDigits = 8;
  }

  if (currency.indexOf('BMX') === 0) {
    fractDigits = 10;
  }

  if (round) {
    value = parseFloat(value).toFixed(fractDigits);
  }

  return prepareMoney(value, fractDigits, fillZeros);
}

function prepareCurrencyTitle(currency) {
  switch (currency) {
    case 'USD':
    case 'usd':
      return '$';

    case 'EUR':
    case 'eur':
      return '€';

    default:
      return (0, _toUpper2["default"])(currency);
  }
}